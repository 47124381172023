@charset "UTF-8";
/*
  SASS variables are information about icon's compiled state, stored under its original file name

  .icon-home
    width: $icon-home-width

  The large array-like variables contain all information about a single icon
  $icon-home: x y offset_x offset_y width height total_width total_height image_path

  At the bottom of this section, we provide information about the spritesheet itself
  $spritesheet: width height image $spritesheet-sprites
  */
/*
  The provided mixins are intended to be used with the array-like variables

  .icon-home
    @include sprite-width($icon-home)

  .icon-email
    @include sprite($icon-email)
  */
/*
  The `sprites` mixin generates identical output to the CSS template
    but can be overridden inside of SASS

  @include sprites($spritesheet-sprites)
  */
.mb1 {
  margin-bottom: 1px !important; }

.mt1 {
  margin-top: 1px !important; }

.mr1 {
  margin-right: 1px !important; }

.mb2 {
  margin-bottom: 2px !important; }

.mt2 {
  margin-top: 2px !important; }

.mr2 {
  margin-right: 2px !important; }

.mb3 {
  margin-bottom: 3px !important; }

.mt3 {
  margin-top: 3px !important; }

.mr3 {
  margin-right: 3px !important; }

.mb4 {
  margin-bottom: 4px !important; }

.mt4 {
  margin-top: 4px !important; }

.mr4 {
  margin-right: 4px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mb6 {
  margin-bottom: 6px !important; }

.mt6 {
  margin-top: 6px !important; }

.mr6 {
  margin-right: 6px !important; }

.mb7 {
  margin-bottom: 7px !important; }

.mt7 {
  margin-top: 7px !important; }

.mr7 {
  margin-right: 7px !important; }

.mb8 {
  margin-bottom: 8px !important; }

.mt8 {
  margin-top: 8px !important; }

.mr8 {
  margin-right: 8px !important; }

.mb9 {
  margin-bottom: 9px !important; }

.mt9 {
  margin-top: 9px !important; }

.mr9 {
  margin-right: 9px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mb11 {
  margin-bottom: 11px !important; }

.mt11 {
  margin-top: 11px !important; }

.mr11 {
  margin-right: 11px !important; }

.mb12 {
  margin-bottom: 12px !important; }

.mt12 {
  margin-top: 12px !important; }

.mr12 {
  margin-right: 12px !important; }

.mb13 {
  margin-bottom: 13px !important; }

.mt13 {
  margin-top: 13px !important; }

.mr13 {
  margin-right: 13px !important; }

.mb19 {
  margin-bottom: 19px !important; }

.mt19 {
  margin-top: 19px !important; }

.mr19 {
  margin-right: 19px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mb26 {
  margin-bottom: 26px !important; }

.mt26 {
  margin-top: 26px !important; }

.mr26 {
  margin-right: 26px !important; }

.mb37 {
  margin-bottom: 37px !important; }

.mt37 {
  margin-top: 37px !important; }

.mr37 {
  margin-right: 37px !important; }

.mb38 {
  margin-bottom: 38px !important; }

.mt38 {
  margin-top: 38px !important; }

.mr38 {
  margin-right: 38px !important; }

.mb39 {
  margin-bottom: 39px !important; }

.mt39 {
  margin-top: 39px !important; }

.mr39 {
  margin-right: 39px !important; }

.mb51 {
  margin-bottom: 51px !important; }

.mt51 {
  margin-top: 51px !important; }

.mr51 {
  margin-right: 51px !important; }

.mb52 {
  margin-bottom: 52px !important; }

.mt52 {
  margin-top: 52px !important; }

.mr52 {
  margin-right: 52px !important; }

.mb63 {
  margin-bottom: 63px !important; }

.mt63 {
  margin-top: 63px !important; }

.mr63 {
  margin-right: 63px !important; }

.mb64 {
  margin-bottom: 64px !important; }

.mt64 {
  margin-top: 64px !important; }

.mr64 {
  margin-right: 64px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.mb66 {
  margin-bottom: 66px !important; }

.mt66 {
  margin-top: 66px !important; }

.mr66 {
  margin-right: 66px !important; }

.mb77 {
  margin-bottom: 77px !important; }

.mt77 {
  margin-top: 77px !important; }

.mr77 {
  margin-right: 77px !important; }

.mb78 {
  margin-bottom: 78px !important; }

.mt78 {
  margin-top: 78px !important; }

.mr78 {
  margin-right: 78px !important; }

.mb79 {
  margin-bottom: 79px !important; }

.mt79 {
  margin-top: 79px !important; }

.mr79 {
  margin-right: 79px !important; }

.mb90 {
  margin-bottom: 90px !important; }

.mt90 {
  margin-top: 90px !important; }

.mr90 {
  margin-right: 90px !important; }

.mb91 {
  margin-bottom: 91px !important; }

.mt91 {
  margin-top: 91px !important; }

.mr91 {
  margin-right: 91px !important; }

.mb107 {
  margin-bottom: 107px !important; }

.mt107 {
  margin-top: 107px !important; }

.mr107 {
  margin-right: 107px !important; }

.mb108 {
  margin-bottom: 108px !important; }

.mt108 {
  margin-top: 108px !important; }

.mr108 {
  margin-right: 108px !important; }

.mb109 {
  margin-bottom: 109px !important; }

.mt109 {
  margin-top: 109px !important; }

.mr109 {
  margin-right: 109px !important; }

.mb110 {
  margin-bottom: 110px !important; }

.mt110 {
  margin-top: 110px !important; }

.mr110 {
  margin-right: 110px !important; }

.mb116 {
  margin-bottom: 116px !important; }

.mt116 {
  margin-top: 116px !important; }

.mr116 {
  margin-right: 116px !important; }

.mb117 {
  margin-bottom: 117px !important; }

.mt117 {
  margin-top: 117px !important; }

.mr117 {
  margin-right: 117px !important; }

.col-225, .col-300, .col-450 {
  float: left; }

.col-225 {
  width: 225px; }

.col-300 {
  width: 300px; }

.col-450 {
  width: 450px; }

.btn-nin, .btn-nyu, .btn-you, .btn-sho, .btn-chu, .btn-kou, .btn-sha, .btn-sen, .txt-ei, .txt-ga, .txt-hou, .txt-jyu, .txt-kai, .txt-shu, .txt-pet, .txt-ryu, .txt-sei, .ic-all-m, .ic-nin-m, .ic-nyu-m, .ic-you-m, .ic-sho-m, .ic-chu-m, .ic-kou-m, .ic-sya-m, .ic-sen-m {
  position: relative;
  display: inline-block;
  vertical-align: middle; }
  .btn-nin:after, .btn-nyu:after, .btn-you:after, .btn-sho:after, .btn-chu:after, .btn-kou:after, .btn-sha:after, .btn-sen:after, .txt-ei:after, .txt-ga:after, .txt-hou:after, .txt-jyu:after, .txt-kai:after, .txt-shu:after, .txt-pet:after, .txt-ryu:after, .txt-sei:after, .ic-all-m:after, .ic-nin-m:after, .ic-nyu-m:after, .ic-you-m:after, .ic-sho-m:after, .ic-chu-m:after, .ic-kou-m:after, .ic-sya-m:after, .ic-sen-m:after {
    display: inline;
    content: ".";
    color: transparent; }

.btn-nin {
  background-image: url(__spriteSheet.png);
  background-position: -119px -119px;
  width: 50px;
  height: 15px;
  background-size: 232px 152px; }

.btn-nyu {
  background-image: url(__spriteSheet.png);
  background-position: -67px -119px;
  width: 50px;
  height: 15px;
  background-size: 232px 152px; }

.btn-you {
  background-image: url(__spriteSheet.png);
  background-position: -171px -119px;
  width: 50px;
  height: 15px;
  background-size: 232px 152px; }

.btn-sho {
  background-image: url(__spriteSheet.png);
  background-position: 0px -119px;
  width: 65px;
  height: 15px;
  background-size: 232px 152px; }

.btn-chu {
  background-image: url(__spriteSheet.png);
  background-position: 0px -102px;
  width: 65px;
  height: 15px;
  background-size: 232px 152px; }

.btn-kou {
  background-image: url(__spriteSheet.png);
  background-position: -67px -102px;
  width: 65px;
  height: 15px;
  background-size: 232px 152px; }

.btn-sha {
  background-image: url(__spriteSheet.png);
  background-position: -117px -51px;
  width: 83px;
  height: 15px;
  background-size: 232px 152px; }

.btn-sen {
  background-image: url(__spriteSheet.png);
  background-position: -134px -102px;
  width: 65px;
  height: 15px;
  background-size: 232px 152px; }

.txt-ei {
  background-image: url(__spriteSheet.png);
  background-position: -117px -34px;
  width: 115px;
  height: 15px;
  background-size: 232px 152px; }

.txt-ga {
  background-image: url(__spriteSheet.png);
  background-position: 0px 0px;
  width: 115px;
  height: 15px;
  background-size: 232px 152px; }

.txt-hou {
  background-image: url(__spriteSheet.png);
  background-position: -117px 0px;
  width: 115px;
  height: 15px;
  background-size: 232px 152px; }

.txt-jyu {
  background-image: url(__spriteSheet.png);
  background-position: 0px -85px;
  width: 115px;
  height: 15px;
  background-size: 232px 152px; }

.txt-kai {
  background-image: url(__spriteSheet.png);
  background-position: 0px -68px;
  width: 115px;
  height: 15px;
  background-size: 232px 152px; }

.txt-shu {
  background-image: url(__spriteSheet.png);
  background-position: 0px -17px;
  width: 115px;
  height: 15px;
  background-size: 232px 152px; }

.txt-pet {
  background-image: url(__spriteSheet.png);
  background-position: 0px -51px;
  width: 115px;
  height: 15px;
  background-size: 232px 152px; }

.txt-ryu {
  background-image: url(__spriteSheet.png);
  background-position: 0px -34px;
  width: 115px;
  height: 15px;
  background-size: 232px 152px; }

.txt-sei {
  background-image: url(__spriteSheet.png);
  background-position: -117px -17px;
  width: 115px;
  height: 15px;
  background-size: 232px 152px; }

.ic-all-m {
  background-image: url(__spriteSheet.png);
  background-position: -117px -68px;
  width: 72.5px;
  height: 16px;
  background-size: 232px 152px; }

.ic-nin-m {
  background-image: url(__spriteSheet.png);
  background-position: -56px -136px;
  width: 26px;
  height: 16px;
  background-size: 232px 152px; }

.ic-nyu-m {
  background-image: url(__spriteSheet.png);
  background-position: -28px -136px;
  width: 26px;
  height: 16px;
  background-size: 232px 152px; }

.ic-you-m {
  background-image: url(__spriteSheet.png);
  background-position: -84px -136px;
  width: 16px;
  height: 16px;
  background-size: 232px 152px; }

.ic-sho-m {
  background-image: url(__spriteSheet.png);
  background-position: -102px -136px;
  width: 16px;
  height: 16px;
  background-size: 232px 152px; }

.ic-chu-m {
  background-image: url(__spriteSheet.png);
  background-position: -138px -136px;
  width: 16px;
  height: 16px;
  background-size: 232px 152px; }

.ic-kou-m {
  background-image: url(__spriteSheet.png);
  background-position: -120px -136px;
  width: 16px;
  height: 16px;
  background-size: 232px 152px; }

.ic-sya-m {
  background-image: url(__spriteSheet.png);
  background-position: 0px -136px;
  width: 26px;
  height: 16px;
  background-size: 232px 152px; }

.ic-sen-m {
  background-image: url(__spriteSheet.png);
  background-position: -191.5px -68px;
  width: 34px;
  height: 16px;
  background-size: 232px 152px; }

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff; }

img {
  border: none;
  vertical-align: bottom; }

strong {
  font-weight: bold; }

a {
  color: #000;
  text-decoration: none; }
  a:hover {
    color: #666; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style-type: none; }

h1, h2, h3, h4 {
  margin: 0; }

hr {
  margin: 0;
  border: none;
  border-top: 1px solid #d9d9d9; }

.header-content, .main-content, .drawer-content-inner {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px; }

.row:after, .anchor-links:after, .menu-list:after, .menu-list--2col:after, .entry-2:after, .relative-feature-entries:after, .recommend-list:after, .torikumi-entries:after {
  content: " ";
  display: block;
  clear: both; }

.header {
  background-color: #fff; }
  .header.header--top {
    position: relative;
    z-index: 9;
    border-radius: 0 0 9px 9px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15); }

.header-content {
  position: relative;
  padding: 0 13px 13px 13px; }

.header-logo {
  display: inline-block;
  padding-top: 10px; }

.header-copy {
  display: inline-block;
  padding-top: 7px;
  float: right; }

.footer {
  padding-top: 4px;
  padding-bottom: 24px;
  background: #f2f2f2 url(bg/footer_border.png) repeat-x top left;
  background-size: auto 2px; }

.footer-content {
  margin: 0 auto;
  padding-left: 18px;
  padding-right: 18px; }

.footer-links1 {
  margin-top: 18px; }

.footer-links2 {
  margin-top: 30px; }

.footer-headline {
  margin-top: 30px; }

.footer-btn-link {
  margin-top: 18px;
  padding: 13px 21px; }

.footer-company-name {
  margin-top: 60px;
  line-height: 10px; }

.footer-copyright {
  margin-top: 12px;
  line-height: 10px; }

.footer-phone-number {
  padding: 5px 0 18px 0; }
  .footer-phone-number .link-basic--after, .footer-phone-number .link-basic--after--blue {
    padding-bottom: 15px; }
    .footer-phone-number .link-basic--after:after, .footer-phone-number .link-basic--after--blue:after {
      top: 54px; }

.btn, .btn--basic, .btn--support {
  text-decoration: underline; }

.btn, .btn--basic, .btn--support {
  line-height: 1; }

.btn-label--plus:before {
  transform: scale(0.5); }

.btn-label--plus:before {
  content: url(ic/plus.png); }

.is-show-more-closed {
  display: inline; }

.is-show-more-opened {
  display: none; }

.is-show-more--open .is-show-more-closed {
  display: none; }

.is-show-more--open .is-show-more-opened {
  display: inline; }

.is-show-more--open .link-plus:before {
  background-image: url(ic/minus.png); }

.main {
  margin-top: 18px; }
  .main[class*="l-service-mokuteki-"] {
    margin-top: 10px; }

.main-visual {
  position: relative; }
  .main-visual img {
    width: 100%; }

.main-visual-container {
  z-index: -1;
  position: relative;
  overflow: hidden;
  border-radius: 9px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2); }

.main-visual-purpose {
  position: absolute;
  z-index: 1;
  top: 4.5%; }

.main-visual-subject {
  position: absolute;
  z-index: 1;
  top: 22.5%; }

.main-visual-benefit {
  position: absolute;
  z-index: 1;
  bottom: -24px;
  left: -6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  width: 217px; }

.l-service-seichou-ninshin .main-visual-benefit {
  width: 209px; }

.l-service-seichou-nyuuji .main-visual-benefit {
  width: 262px; }

.l-service-seichou-youji .main-visual-benefit {
  width: 215px; }

.l-service-seichou-shougakusei .main-visual-benefit {
  width: 217px; }

.l-service-seichou-chuugakusei .main-visual-benefit {
  width: 235px; }

.l-service-seichou-koukousei .main-visual-benefit {
  width: 224px; }

.l-service-seichou-shakaijin .main-visual-benefit {
  width: 207px; }

.l-service-seichou-senior .main-visual-benefit {
  width: 233px; }

.main-visual-2-container {
  margin-top: 18px;
  overflow: hidden;
  border-radius: 9px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2); }
  .main-visual-2-container img {
    width: 100%; }

.main-visual-2-subject {
  margin-top: 9px;
  line-height: 18px; }

.main-visual-2-benefit {
  margin-top: 18px; }
  .main-visual-2-benefit img {
    width: 100%; }

.headline--accent {
  background: url("ic/headline_accent.png") no-repeat 0 2px;
  background-size: 18px 15px;
  line-height: 18px;
  padding-left: 29px; }

.headline--ninshin {
  background: url("ic/headline_ninshin.png") no-repeat 0 0;
  background-size: 17px 17px;
  line-height: 17px;
  padding-left: 29px; }

.headline--nyuuji {
  background: url("ic/headline_nyuuji.png") no-repeat 0 0;
  background-size: 17px 17px;
  line-height: 17px;
  padding-left: 29px; }

.headline--youji {
  background: url("ic/headline_youji.png") no-repeat 0 0;
  background-size: 17px 17px;
  line-height: 17px;
  padding-left: 29px; }

.headline--shougakusei {
  background: url("ic/headline_shougakusei.png") no-repeat 0 0;
  background-size: 17px 17px;
  line-height: 17px;
  padding-left: 29px; }

.headline--chuugakusei {
  background: url("ic/headline_chuugakusei.png") no-repeat 0 0;
  background-size: 17px 17px;
  line-height: 17px;
  padding-left: 29px; }

.headline--koukousei {
  background: url("ic/headline_koukousei.png") no-repeat 0 0;
  background-size: 17px 17px;
  line-height: 17px;
  padding-left: 29px; }

.headline--shakaijin {
  background: url("ic/headline_shakaijin.png") no-repeat 0 0;
  background-size: 17px 17px;
  line-height: 17px;
  padding-left: 29px; }

.headline--senior {
  background: url("ic/headline_senior.png") no-repeat 0 0;
  background-size: 17px 17px;
  line-height: 17px;
  padding-left: 29px; }

.headline--mokuteki {
  background: url("ic/headline_mokuteki.png") no-repeat 0 0;
  background-size: 17px 17px;
  line-height: 17px;
  padding-left: 29px; }

.anchor-links {
  padding: 0;
  margin: 48px 0 15px 0; }
  .anchor-links li {
    list-style-type: none;
    line-height: 17px;
    margin-top: 12px;
    padding-left: 24px;
    background: url("ic/ar_basic_d_m.png") no-repeat 0 0;
    background-size: auto 17px; }
    .anchor-links li a {
      display: inline-block; }
    .anchor-links li:first-child {
      margin-top: 0; }

.l-service-seichou-ninshin .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_ninshin_d_m.png") no-repeat 0 0;
  background-size: auto 17px; }

.l-service-seichou-nyuuji .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_nyuuji_d_m.png") no-repeat 0 0;
  background-size: auto 17px; }

.l-service-seichou-youji .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_youji_d_m.png") no-repeat 0 0;
  background-size: auto 17px; }

.l-service-seichou-shougakusei .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_shougakusei_d_m.png") no-repeat 0 0;
  background-size: auto 17px; }

.l-service-seichou-chuugakusei .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_chuugakusei_d_m.png") no-repeat 0 0;
  background-size: auto 17px; }

.l-service-seichou-koukousei .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_koukousei_d_m.png") no-repeat 0 0;
  background-size: auto 17px; }

.l-service-seichou-shakaijin .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_shakaijin_d_m.png") no-repeat 0 0;
  background-size: auto 17px; }

.l-service-seichou-senior .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_senior_d_m.png") no-repeat 0 0;
  background-size: auto 17px; }

[class*="l-service-mokuteki-"] .anchor-links {
  margin-top: 24px; }
  [class*="l-service-mokuteki-"] .anchor-links li {
    padding-left: 24px;
    background: url("ic/ar_mokuteki_d_m.png") no-repeat 0 0;
    background-size: auto 17px; }

.proposal {
  margin-top: 18px;
  padding-top: 24px; }

.proposal-headline {
  position: relative;
  margin: 0;
  background: url(bg/proposal_headline.png) no-repeat left bottom;
  background-size: auto 47px;
  height: 47px;
  padding-left: 24px;
  line-height: 17px; }
  .proposal-headline:after {
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    content: " ";
    border-radius: 0 3px 0 0;
    height: 6px;
    width: 100%;
    background-color: #ccc; }

.proposal-ninshin .proposal-headline {
  background-image: url(bg/proposal_headline_ninshin.png); }

.proposal-ninshin .proposal-service-more:before {
  content: url(ic/plus_ninshin.png); }

.proposal-ninshin .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_ninshin.png); }

.proposal-nyuuji .proposal-headline {
  background-image: url(bg/proposal_headline_nyuuji.png); }

.proposal-nyuuji .proposal-service-more:before {
  content: url(ic/plus_nyuuji.png); }

.proposal-nyuuji .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_nyuuji.png); }

.proposal-youji .proposal-headline {
  background-image: url(bg/proposal_headline_youji.png); }

.proposal-youji .proposal-service-more:before {
  content: url(ic/plus_youji.png); }

.proposal-youji .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_youji.png); }

.proposal-shougakusei .proposal-headline {
  background-image: url(bg/proposal_headline_shougakusei.png); }

.proposal-shougakusei .proposal-service-more:before {
  content: url(ic/plus_shougakusei.png); }

.proposal-shougakusei .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_shougakusei.png); }

.proposal-chuugakusei .proposal-headline {
  background-image: url(bg/proposal_headline_chuugakusei.png); }

.proposal-chuugakusei .proposal-service-more:before {
  content: url(ic/plus_chuugakusei.png); }

.proposal-chuugakusei .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_chuugakusei.png); }

.proposal-koukousei .proposal-headline {
  background-image: url(bg/proposal_headline_koukousei.png); }

.proposal-koukousei .proposal-service-more:before {
  content: url(ic/plus_koukousei.png); }

.proposal-koukousei .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_koukousei.png); }

.proposal-shakaijin .proposal-headline {
  background-image: url(bg/proposal_headline_shakaijin.png); }

.proposal-shakaijin .proposal-service-more:before {
  content: url(ic/plus_shakaijin.png); }

.proposal-shakaijin .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_shakaijin.png); }

.proposal-senior .proposal-headline {
  background-image: url(bg/proposal_headline_senior.png); }

.proposal-senior .proposal-service-more:before {
  content: url(ic/plus_senior.png); }

.proposal-senior .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_senior.png); }

/** NOTE: 同じ色なので画像を使い回し */
.proposal-eigo .proposal-headline {
  background-image: url(bg/proposal_headline_mokuteki.png); }

.proposal-eigo .proposal-service-more:before {
  content: url(ic/plus_mokuteki.png); }

.proposal-eigo .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_mokuteki.png); }

.proposal-juken .proposal-headline {
  background-image: url(bg/proposal_headline_mokuteki.png); }

.proposal-juken .proposal-service-more:before {
  content: url(ic/plus_mokuteki.png); }

.proposal-juken .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_mokuteki.png); }

.proposal-kaigai .proposal-headline {
  background-image: url(bg/proposal_headline_mokuteki.png); }

.proposal-kaigai .proposal-service-more:before {
  content: url(ic/plus_mokuteki.png); }

.proposal-kaigai .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_mokuteki.png); }

.proposal-kaigo .proposal-headline {
  background-image: url(bg/proposal_headline_mokuteki.png); }

.proposal-kaigo .proposal-service-more:before {
  content: url(ic/plus_mokuteki.png); }

.proposal-kaigo .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_mokuteki.png); }

.proposal-pet .proposal-headline {
  background-image: url(bg/proposal_headline_mokuteki.png); }

.proposal-pet .proposal-service-more:before {
  content: url(ic/plus_mokuteki.png); }

.proposal-pet .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_mokuteki.png); }

.proposal-seiseki .proposal-headline {
  background-image: url(bg/proposal_headline_mokuteki.png); }

.proposal-seiseki .proposal-service-more:before {
  content: url(ic/plus_mokuteki.png); }

.proposal-seiseki .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_mokuteki.png); }

.proposal-shussan .proposal-headline {
  background-image: url(bg/proposal_headline_mokuteki.png); }

.proposal-shussan .proposal-service-more:before {
  content: url(ic/plus_mokuteki.png); }

.proposal-shussan .is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus_mokuteki.png); }

.proposal-services {
  margin: 0;
  padding: 0; }

.proposal-service {
  position: relative;
  list-style-type: none;
  background: url(bg/proposal.png) no-repeat left bottom;
  background-size: auto 164px; }
  .proposal-service a {
    position: relative;
    display: block;
    height: 55px;
    background: url(ic/blank.png) no-repeat right center;
    background-size: 11px; }
  .proposal-service .proposal-service-logo {
    display: none; }
  .proposal-service .proposal-service-name {
    display: table; }
  .proposal-service:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3; }
  .proposal-service.proposal-service-large,
  .proposal-service:first-child {
    background: url(bg/proposal.png) no-repeat left top;
    background-size: auto 164px; }
    .proposal-service.proposal-service-large a,
    .proposal-service:first-child a {
      height: 109px; }
    .proposal-service.proposal-service-large .proposal-service-logo,
    .proposal-service:first-child .proposal-service-logo {
      display: table-cell; }
    .proposal-service.proposal-service-large .proposal-service-name,
    .proposal-service:first-child .proposal-service-name {
      display: none; }
    .proposal-service.proposal-service-large .proposal-service-copy,
    .proposal-service:first-child .proposal-service-copy {
      width: 80px; }

.proposal-service-copy {
  display: table;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  font-size: 12px;
  line-height: 17px;
  padding-left: 13px; }
  .proposal-service-copy span {
    display: table-cell;
    vertical-align: middle; }

.proposal-service-logo, .proposal-service-name {
  display: table;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto; }
  .proposal-service-logo span, .proposal-service-name span {
    display: table-cell;
    vertical-align: middle; }

.proposal-service-logo {
  left: 143px;
  height: 76px; }
  .proposal-service-logo img {
    height: 100%; }

.proposal-service-name {
  width: 45%;
  left: 143px;
  font-size: 12px;
  line-height: 17px; }

.is-proposal-service-fold {
  display: none; }

.proposal-service-more {
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid #ccc;
  height: 54px; }
  .proposal-service-more:before {
    content: url(ic/plus.png);
    transform: scale(0.5);
    display: inline-block;
    margin-top: 11px;
    margin-left: 20px; }
  .proposal-service-more:after {
    content: url(txt/read_more.png);
    transform: scale(0.5);
    display: inline-block;
    margin-top: 11px;
    margin-left: -28px; }

.is-proposal-service--open .proposal-service-more:before {
  content: url(ic/minus.png);
  margin-left: 5px; }

.is-proposal-service--open .proposal-service-more:after {
  content: url(txt/read_close.png);
  margin-left: -18px; }

.proposal-service-pop {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 168px;
  width: 289px;
  height: 115px;
  margin-top: -56px;
  background: url(bg/proposal_pop.png) no-repeat; }
  .proposal-service-pop img {
    position: absolute;
    margin: auto;
    top: 10px;
    left: 60px;
    width: 166px;
    height: 91px; }

.proposal-service-pop-icon {
  position: absolute;
  right: 12px;
  top: 10px; }

.slide-content {
  margin-top: -8px; }

.slide-visual {
  position: relative;
  min-width: 320px;
  overflow: hidden; }

.slide-visual-container {
  /** maybe override */
  width: 5120px;
  height: 158px;
  background-color: #f3f3f3; }
  .slide-visual-container img {
    width: auto;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    vertical-align: bottom;
    opacity: 0; }

.menu {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }

.menu-header, .menu-medium, .menu-footer {
  position: relative;
  height: 30px;
  line-height: 15px;
  background-color: #f2f2f2;
  border-radius: 3px 4px 0 0;
  padding-left: 12px;
  padding-top: 8px;
  box-sizing: border-box;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 0; }
  .menu-header img, .menu-medium img, .menu-footer img {
    position: relative;
    z-index: 1; }
  .menu-header:before, .menu-medium:before, .menu-footer:before {
    content: " ";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 50%;
    height: 30px;
    background: #0075c2 url(bg/menu_sep.png) no-repeat right center;
    background-size: auto 30px;
    border-radius: 3px 0 0 0; }

.menu-header-tab {
  display: block;
  position: absolute;
  padding-top: 5px;
  top: -19px;
  right: 4px;
  width: 121px;
  height: 20px;
  background: url(bg/menu_header_tab.png) no-repeat left top;
  background-size: 121px 20px;
  text-align: center; }

.menu-medium {
  border-radius: 0 0 0 0; }
  .menu-medium:before {
    border-radius: 0 0 0 0; }

.menu-footer {
  border-radius: 0 0 3px 4px;
  padding-top: 7px; }
  .menu-footer:before {
    border-radius: 0 0 0 3px; }
  .menu-footer.menu-footer--top {
    background-color: #5c5c5c; }
    .menu-footer.menu-footer--top:before {
      background-image: url(bg/menu_sep2.png); }

.menu-footer-left {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 15px; }

.menu-footer-right {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 15px;
  left: 50%;
  padding-left: 9px; }

.menu-list, .menu-list--2col {
  background-color: #fff;
  padding-left: 0;
  margin: 0; }
  .menu-list li, .menu-list--2col li {
    list-style-type: none;
    box-sizing: border-box;
    float: left;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
    .menu-list li a, .menu-list--2col li a {
      display: block;
      padding: 10px 0 13px 9px;
      line-height: 15px; }

.menu-list li {
  width: 33.334%; }
  .menu-list li:nth-child(1) a,
  .menu-list li:nth-child(2) a,
  .menu-list li:nth-child(3) a {
    padding-top: 11px; }
  .menu-list li:nth-child(3n) {
    border-right: none; }
  .menu-list li:nth-child(7),
  .menu-list li:nth-child(8),
  .menu-list li:nth-child(9) {
    border-bottom: none; }

.menu-list--2col li {
  width: 50%; }
  .menu-list--2col li:nth-child(1) a,
  .menu-list--2col li:nth-child(2) a {
    padding-top: 11px; }
  .menu-list--2col li:nth-child(2n) {
    border-right: none; }
  .menu-list--2col li:nth-child(9),
  .menu-list--2col li:nth-child(10) {
    border-bottom: none; }

.entry {
  display: block;
  text-decoration: none; }

.entry-image img {
  width: 100%; }

.entry-title {
  box-sizing: border-box;
  margin-top: 16px;
  padding-right: 15px;
  display: block;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px; }

.entry-description {
  box-sizing: border-box;
  margin-top: 11px;
  padding-right: 15px;
  display: block;
  color: #666;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none; }

.entry-2 {
  display: block; }

.entry-2-image {
  float: left;
  width: 84px;
  height: 84px;
  margin-top: 4px;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }
  .entry-2-image img {
    width: 100%; }

.entry-2-title {
  color: #000;
  margin-left: 102px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px; }

.entry-2-description {
  color: #666;
  margin-left: 102px;
  font-size: 10px;
  line-height: 18px;
  text-decoration: none; }

.notfound-headline {
  margin-bottom: 30px; }
  .notfound-headline img {
    width: 100%; }

.notfound-description {
  font-size: 12px;
  line-height: 20px; }

.info-entry {
  margin-bottom: 56px; }

.info-entry-title {
  font-weight: normal;
  font-size: 18px;
  padding-bottom: 12px;
  padding-right: 45px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 22px; }

.info-entry-date {
  line-height: 28px;
  font-size: 14px;
  margin-bottom: 17px; }

.info-entry-body {
  line-height: 28px; }
  .info-entry-body p {
    font-size: 14px;
    margin: 0 auto 18px; }
  .info-entry-body h4 {
    font-size: 14px;
    margin: 20px auto 10px; }

.info-entry-backto {
  font-size: 13px;
  margin-top: 26px; }
  .info-entry-backto .link-basic {
    line-height: 20px;
    position: relative;
    display: inline-block;
    padding-left: 24px; }
    .info-entry-backto .link-basic:before {
      position: absolute;
      top: 2px;
      left: 0; }

.announcement {
  position: relative;
  background-color: #f2f2f2;
  padding: 12px 11px;
  margin-top: 24px;
  margin-bottom: 32px; }
  .announcement .announcement-list li {
    line-height: 20px; }
  .announcement .announcement-list [class^="link-"] {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    line-height: 20px;
    font-size: 12px;
    vertical-align: bottom; }
    .announcement .announcement-list [class^="link-"]:before {
      position: absolute;
      top: 3px;
      left: 0; }

.is-announcement-hidden {
  display: none; }

.link-basic, .link-plus, .link-notice, .link-blank {
  display: block;
  position: relative; }
  .link-basic:before, .link-plus:before, .link-notice:before, .link-blank:before {
    display: inline-block;
    content: " ";
    vertical-align: middle;
    margin-right: 6px; }

.link-basic {
  line-height: 15px; }
  .link-basic:before {
    background: url(ic/link_basic.png) no-repeat left center;
    background-size: 15px;
    width: 15px;
    height: 15px; }

.link-plus {
  line-height: 15px; }
  .link-plus:before {
    background: url(ic/plus.png) no-repeat left center;
    background-size: 15px;
    width: 15px;
    height: 15px; }

.link-notice {
  line-height: 15px; }
  .link-notice:before {
    background: url(ic/notice.png) no-repeat left center;
    background-size: 15px;
    width: 15px;
    height: 15px; }

.link-blank {
  line-height: 15px; }
  .link-blank:before {
    background: url(ic/blank.png) no-repeat left center;
    background-size: 11px;
    width: 15px;
    height: 15px; }

.link-basic--after, .link-basic--after--blue, .link-plus--after, .link-blank--after {
  display: block;
  position: relative;
  box-sizing: border-box;
  cursor: pointer; }
  .link-basic--after:after, .link-basic--after--blue:after, .link-plus--after:after, .link-blank--after:after {
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto; }

.link-basic--after, .link-basic--after--blue {
  line-height: 13px; }
  .link-basic--after:after, .link-basic--after--blue:after {
    right: 11px;
    background: url(ic/link_basic.png) no-repeat right center;
    background-size: 15px;
    width: 15px;
    height: 15px; }

.link-basic--after--blue:after {
  right: 14px;
  background: url(ic/link_basic_blue.png) no-repeat right center;
  background-size: 15px;
  width: 15px;
  height: 15px; }

.link-plus--after {
  line-height: 13px; }
  .link-plus--after:after {
    right: 11px;
    background: url(ic/plus.png) no-repeat right center;
    background-size: 15px;
    width: 15px;
    height: 15px; }

.link-blank--after {
  line-height: 13px; }
  .link-blank--after:after {
    right: 11px;
    background: url(ic/blank.png) no-repeat center;
    background-size: 11px;
    width: 15px;
    height: 15px; }

.list-box {
  background: #f3f3f3;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  padding: 0; }
  .list-box li {
    border-bottom: 1px solid #d4d4d4;
    list-style: none; }
    .list-box li:last-child {
      border-bottom: none; }
  .list-box ul {
    margin: 0;
    padding: 0;
    border-top: 1px solid #d4d4d4; }

.list-box--indent0 > li > a,
.list-box--indent0 > li > span {
  padding: 19px 13px 20px; }

.list-box--indent1 > li > a,
.list-box--indent1 > li > span {
  padding: 19px 26px 20px; }

.list-box--indent2 > li > a,
.list-box--indent2 > li > span {
  padding: 19px 39px 20px; }

.list-box-content {
  padding: 0 13px; }

.is-list-box-fold {
  display: none; }

.is-list-toggle-opened {
  display: none; }

.is-list-toggle-closed {
  display: inline; }

.is-list-toggle-open.link-plus--after:after {
  /** NOTE: 少々強引かもしれない… */
  background-image: url(ic/minus.png); }

.is-list-toggle-open .is-list-toggle-opened {
  display: inline; }

.is-list-toggle-open .is-list-toggle-closed {
  display: none; }

.btn, .btn--basic, .btn--support {
  text-align: center;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #fff;
  padding: 10px 0 12px; }

.btn--support {
  color: #fff;
  background-color: #0075c2;
  text-align: left;
  padding: 18px 0 20px 13px;
  border: 1px solid #0075c2; }

.note, .note--aster {
  color: #666;
  font-size: 10px;
  letter-spacing: 0.6px;
  line-height: 18px;
  margin: 0;
  padding: 0; }

.note--aster:before {
  content: "※"; }

.other-services {
  margin-top: 42px; }

.other-services-list {
  margin-top: 24px;
  box-sizing: border-box;
  padding: 18px;
  border: 1px solid #e3e3e3;
  border-radius: 3px; }
  .other-services-list li {
    margin-top: 13px;
    font-size: 12px; }
    .other-services-list li:first-child {
      margin-top: 0; }
    .other-services-list li a {
      font-weight: bold;
      vertical-align: bottom; }
    .other-services-list li .link-blank:before {
      position: relative;
      top: -2px; }

.relative-feature {
  margin-top: 42px; }

.relative-feature-entries {
  margin-top: 24px; }
  .relative-feature-entries li {
    float: left;
    display: block;
    width: 50%;
    margin-top: 24px; }
    .relative-feature-entries li .entry-image {
      overflow: hidden; }
    .relative-feature-entries li .entry-description {
      margin-top: 9px; }
    .relative-feature-entries li:nth-child(1),
    .relative-feature-entries li:nth-child(2) {
      margin-top: 0; }
    .relative-feature-entries li:nth-child(2n+1) .entry-image {
      border-radius: 3px 0 0 3px;
      box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2); }
    .relative-feature-entries li:nth-child(2n) .entry-image {
      border-radius: 0 3px 3px 0;
      box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2); }

.relative-feature-more {
  margin-top: 20px; }

.recommend {
  margin-top: 42px; }

.recommend-list {
  margin-top: 24px;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  overflow: hidden; }
  .recommend-list img {
    width: 100%; }
  .recommend-list li {
    float: left;
    display: block;
    width: 50%;
    margin-top: 0; }
    .recommend-list li .entry-image {
      overflow: hidden; }

.recommend-more {
  margin-top: 20px; }

.find-services {
  margin-top: 42px;
  margin-bottom: 54px; }

.important-info {
  margin-top: 42px;
  margin-bottom: 50px; }

.important-info-list {
  margin-top: 21px; }
  .important-info-list li {
    margin-top: 7px; }
    .important-info-list li:first-child {
      margin-top: 0; }
  .important-info-list [class^="link-"] {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    font-size: 12px;
    line-height: 20px; }
    .important-info-list [class^="link-"]:before {
      position: absolute;
      top: 3px;
      left: 0; }

.drawer-trigger {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 6px;
  cursor: pointer;
  background: url(ic/drawer_trigger.png) no-repeat left top;
  background-size: 65px auto;
  width: 65px;
  height: 50px;
  display: inline-block; }
  .drawer-trigger:after {
    display: inline;
    content: ".";
    color: transparent; }

.drawer-content {
  z-index: 9990;
  position: fixed;
  display: none;
  background-color: #fff;
  height: 100%; }

.drawer-content-inner {
  padding-bottom: 30px;
  height: 100%; }

.drawer-logo {
  display: block;
  padding-top: 10px;
  padding-left: 1px;
  margin-bottom: 31px; }

.drawer-links.list-box {
  margin-top: 18px;
  background-color: #fff; }

/** extra styles for drawer */
.is-drawer-open {
  height: 100%; }
  .is-drawer-open body {
    height: 100%;
    overflow: hidden; }
  .is-drawer-open .wrapper-for-drawer {
    height: 1px;
    overflow: hidden; }
  .is-drawer-open .drawer-content-inner {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    box-sizing: border-box; }
  .is-drawer-open .drawer-trigger {
    background-position: left bottom; }

.event {
  margin-top: 42px; }

.event-entries li {
  margin-top: 16px; }
  .event-entries li:first-child {
    margin-top: 0; }

.event-more {
  margin-top: 19px; }

.torikumi {
  margin-top: 42px; }

.torikumi-entries {
  margin-top: 24px; }
  .torikumi-entries li {
    float: left;
    display: block;
    width: 50%;
    margin-top: 19px; }
    .torikumi-entries li .entry-image {
      overflow: hidden; }
    .torikumi-entries li:nth-child(1),
    .torikumi-entries li:nth-child(2) {
      margin-top: 0; }
    .torikumi-entries li:nth-child(2n+1) .entry-image {
      border-radius: 3px 0 0 3px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }
    .torikumi-entries li:nth-child(2n) .entry-image {
      border-radius: 0 3px 3px 0;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }
    .torikumi-entries li:nth-last-child(1):nth-child(odd) .entry-image {
      border-radius: 3px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }

.torikumi-more {
  margin-top: 24px; }

.top {
  margin-top: -12px; }

.searchbox {
  margin-top: 18px; }

.searchbox-input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  padding: 12px 20px 12px 28px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #f2f2f2;
  background-image: url(ic/search.png);
  background-size: 14px;
  background-position: 10px 14px;
  background-repeat: no-repeat; }
